import clsx from "clsx"
import React from "react"
import AppStoreBadges from "../AppStoreBadges"
import * as styles from "./DownloadApp.module.scss"
import PromoBlade from "./PromoBlade"

export interface DownloadAppProps {
  children: React.ReactNode

  className?: string
}

const DownloadApp = ({ children, className }: DownloadAppProps) => {
  return (
    <PromoBlade className={clsx(styles.downloadApp, className)}>
      <div className="mb-4">{children}</div>

      <AppStoreBadges align="start" />
    </PromoBlade>
  )
}

export default DownloadApp
