import React from "react"
import MailChimpForm from "./MailChimpForm"
import * as styles from "./NewsletterSignup.module.scss"
import PromoBlade from "./PromoBlade"

export interface NewsletterSignupProps {
  className?: string
}

const NewsletterSignup = ({ className }: NewsletterSignupProps) => {
  return (
    <PromoBlade className={className}>
      <h3>
        Stay in the loop with our latest releases and let us know what you want
        to see next.
      </h3>

      <MailChimpForm className={styles.form} />
    </PromoBlade>
  )
}

export default NewsletterSignup
