import clsx from "clsx"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./AppScreenshot.module.scss"

export interface AppScreenshotProps {
  /**
   * Name of the screenshot to display. Must match the `screenshot-<name>.png`
   * pattern in `src/images`.
   */
  name: string

  /**
   * Alt text for the screenshot image.
   */
  alt: string

  /**
   * Optional copy to display along with the screenshot.
   */
  copy?: string | React.ReactElement

  /**
   * Side of the image to display the additional copy.
   */
  copyPosition?: "above" | "side" | "below"

  /**
   * When `copyPosition` is "side", which side?
   */
  justifyCopy?: "start" | "end"

  /**
   * Text alignment for the copy.
   */
  alignCopy?: "start" | "center" | "end"

  /**
   * Style specific parts of the component with classNames.
   */
  classes?: Record<string, string>

  className?: string
}

interface AllScreenshotImagesResult {
  allFile: {
    nodes: {
      name: string
      childrenImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }[]
    }[]
  }
}

const AppScreenshot = ({
  name,
  alt,
  copy,
  copyPosition = "side",
  justifyCopy = "end",
  alignCopy = "start",
  classes = {},
  className,
  ...rest
}: AppScreenshotProps) => {
  const data = useStaticQuery<AllScreenshotImagesResult>(graphql`
    query AllScreenshotImages {
      allFile(filter: { relativePath: { regex: "/screenshot-/" } }) {
        nodes {
          childrenImageSharp {
            gatsbyImageData(formats: PNG, layout: CONSTRAINED, quality: 90)
          }
          name
        }
      }
    }
  `)

  const imageData = data.allFile.nodes.find(
    node => node.name === `screenshot-${name}`
  )

  if (!imageData) {
    throw new Error(`Unable to find image for screenshot: ${name}`)
  }

  // First (and only) image.
  const firstImage = imageData.childrenImageSharp[0].gatsbyImageData

  const renderedImage = (
    <div className={clsx(styles.image, classes.image)}>
      <GatsbyImage image={firstImage} loading="eager" alt={alt} />
    </div>
  )

  const renderedCopy = copy ? (
    <p
      className={clsx(
        "h5",
        styles.copy,
        justifyCopy === "start" && styles.copyJustifyStart,
        alignCopy === "center" && styles.copyAlignCenter,
        (copyPosition === "above" || copyPosition === "below") &&
          styles.copyStacked,
        copyPosition === "below" && styles.copyStackedBelow,
        classes.copy
      )}
    >
      {copy}
    </p>
  ) : null

  return (
    <div
      className={clsx(
        styles.screenshot,
        (copyPosition === "above" || copyPosition === "below") &&
          styles.stacked,
        className
      )}
      {...rest}
    >
      {renderedCopy}
      {renderedImage}
    </div>
  )
}

export default AppScreenshot
