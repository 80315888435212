import clsx from "clsx"
import React from "react"
import { Button, Form } from "react-bootstrap"

export interface MailChimpFormProps {
  className?: string
}

/**
 * Display a simple MailChimp form that submits the email address to a new tab.
 */
const MailChimpForm = ({ className }: MailChimpFormProps) => {
  return (
    <Form
      action="https://app.us6.list-manage.com/subscribe/post?u=f7093ea859aa4d3eaa4c39dd8&amp;id=57efccc216"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      className={clsx("mb-6 mb-md-0", className)}
    >
      <Form.Group className="mb-3" controlId="mce-EMAIL">
        <Form.Label>Enter your email address</Form.Label>
        <Form.Control
          type="email"
          name="EMAIL"
          defaultValue=""
          // Require a TLD in the email address, as per MailChimp validation rules.
          pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
        />
      </Form.Group>

      {/* Real people should not fill this in and expect good things - do not remove this or risk form bot signups. */}
      <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
        <input
          type="text"
          name="b_f7093ea859aa4d3eaa4c39dd8_57efccc216"
          tabIndex={-1}
          defaultValue=""
        />
      </div>

      <Button id="mc-embedded-subscribe" variant="success" type="submit">
        Sign up for emails
      </Button>
    </Form>
  )
}

export default MailChimpForm
