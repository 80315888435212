import clsx from "clsx"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import AppScreenshot from "../AppScreenshot"
import BackgroundGradients from "../BackgroundGradients"
import * as styles from "./PromoBlade.module.scss"

export interface PromoBladeProps {
  children: React.ReactNode

  className?: string
}

const PromoBlade = ({ children, className }: PromoBladeProps) => {
  return (
    <div className={clsx(styles.promoBlade, className)}>
      <div className={styles.content}>
        <Container fluid="xxl">
          <Row className="gy-5">
            <Col md={{ span: 6, offset: 1, order: 1 }}>{children}</Col>
            <Col md={{ span: 5 }}>
              <div className={styles.screenshotWrapper}>
                <AppScreenshot
                  name="onboarding-screen-6"
                  alt="Screenshot of GritLife app"
                  className={styles.screenshot}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <BackgroundGradients noise className={styles.backgroundGradients}>
        <div className={styles.black1}></div>
        <div className={styles.yellow1}></div>
      </BackgroundGradients>
    </div>
  )
}

export default PromoBlade
